import React, { Component } from "react"

export default class SpotifyLogo extends Component {
  render() {
    return (
      <svg
        version="1.1"
        className={this.props.baseLayer}
        x="0px"
        y="0px"
        viewBox="0 0 559 168"
      >
        <path
          className="st0"
          d="M84,0.3C37.7,0.3,0.3,37.8,0.3,84c0,46.3,37.5,83.7,83.7,83.7c46.3,0,83.7-37.5,83.7-83.7
            C167.7,37.8,130.2,0.3,84,0.3C84,0.3,84,0.3,84,0.3z M122.4,121.1c-1.5,2.5-4.7,3.2-7.2,1.7c-19.7-12-44.4-14.7-73.6-8.1
            c-2.8,0.6-5.6-1.1-6.2-3.9c-0.6-2.8,1.1-5.6,3.9-6.2c31.9-7.3,59.3-4.2,81.3,9.3C123.1,115.4,123.9,118.6,122.4,121.1z M132.6,98.3
            c-1.9,3.1-5.9,4-9,2.2c-22.5-13.8-56.8-17.8-83.4-9.8c-3.5,1-7.1-0.9-8.1-4.3c-1-3.5,0.9-7.1,4.4-8.1c30.4-9.2,68.2-4.8,94.1,11.1
            C133.6,91.2,134.5,95.2,132.6,98.3L132.6,98.3z M133.5,74.5c-27-16-71.5-17.5-97.3-9.7c-4.1,1.3-8.5-1.1-9.8-5.2
            c-1.3-4.1,1.1-8.5,5.2-9.8c29.6-9,78.8-7.2,109.8,11.2c3.7,2.2,4.9,7,2.7,10.7C142.1,75.5,137.2,76.7,133.5,74.5L133.5,74.5z
            M228.1,77.6c-14.5-3.4-17-5.9-17-11c0-4.8,4.5-8,11.2-8c6.5,0,13,2.5,19.8,7.5c0.2,0.2,0.5,0.2,0.7,0.2c0.3,0,0.5-0.2,0.6-0.4
            l7.1-10c0.3-0.4,0.2-1-0.2-1.3c-8.1-6.5-17.1-9.6-27.8-9.6c-15.6,0-26.5,9.4-26.5,22.8c0,14.4,9.4,19.5,25.7,23.4
            c13.8,3.2,16.2,5.9,16.2,10.6c0,5.3-4.7,8.6-12.3,8.6c-8.4,0-15.3-2.8-23-9.5c-0.2-0.2-0.4-0.2-0.7-0.2c-0.3,0-0.5,0.1-0.6,0.3
            l-7.9,9.4c-0.3,0.4-0.3,1,0.1,1.3c9,8,20,12.2,31.9,12.2c16.8,0,27.7-9.2,27.7-23.4C252.9,88.5,245.7,81.9,228.1,77.6
            C228.1,77.6,228.1,77.6,228.1,77.6z M291,63.3c-7.3,0-13.3,2.9-18.2,8.8v-6.6c0-0.5-0.4-0.9-0.9-0.9h-13c-0.5,0-0.9,0.4-0.9,0.9
            v73.6c0,0.5,0.4,0.9,0.9,0.9h13c0.5,0,0.9-0.4,0.9-0.9v-23.2c4.9,5.5,10.9,8.2,18.2,8.2c13.5,0,27.3-10.4,27.3-30.4
            C318.2,73.8,304.5,63.3,291,63.3L291,63.3L291,63.3z M303.2,93.7c0,10.1-6.2,17.2-15.2,17.2c-8.9,0-15.5-7.4-15.5-17.2
            c0-9.8,6.7-17.2,15.5-17.2C296.8,76.5,303.2,83.7,303.2,93.7L303.2,93.7z M353.4,63.3c-17.5,0-31.1,13.4-31.1,30.6
            c0,17,13.6,30.3,30.9,30.3c17.5,0,31.2-13.4,31.2-30.5C384.4,76.7,370.8,63.3,353.4,63.3L353.4,63.3z M353.4,111
            c-9.3,0-16.3-7.5-16.3-17.3c0-9.9,6.8-17.1,16.1-17.1c9.3,0,16.4,7.5,16.4,17.4C369.5,103.8,362.7,111,353.4,111z M421.6,64.5h-14.2
            V49.9c0-0.5-0.4-0.9-0.9-0.9h-13c-0.5,0-1,0.4-1,0.9v14.6h-6.2c-0.5,0-0.9,0.4-0.9,0.9v11.1c0,0.5,0.4,0.9,0.9,0.9h6.2v28.8
            c0,11.6,5.8,17.5,17.2,17.5c4.6,0,8.5-1,12.1-3c0.3-0.2,0.5-0.5,0.5-0.8v-10.6c0-0.3-0.2-0.6-0.5-0.8c-0.3-0.2-0.6-0.2-0.9,0
            c-2.5,1.2-4.9,1.8-7.6,1.8c-4.1,0-6-1.9-6-6.1V77.5h14.2c0.5,0,0.9-0.4,0.9-0.9V65.5C422.6,64.9,422.2,64.5,421.6,64.5L421.6,64.5
            L421.6,64.5z M471.3,64.6v-1.8c0-5.3,2-7.6,6.5-7.6c2.7,0,4.9,0.5,7.3,1.3c0.3,0.1,0.6,0,0.9-0.1c0.2-0.2,0.4-0.5,0.4-0.8V44.7
            c0-0.4-0.3-0.8-0.7-0.9c-2.6-0.8-5.8-1.5-10.8-1.5c-12,0-18.3,6.7-18.3,19.5v2.7h-6.2c-0.5,0-1,0.4-1,0.9v11.2c0,0.5,0.4,0.9,1,0.9
            h6.2v44.4c0,0.5,0.4,0.9,1,0.9h12.9c0.5,0,1-0.4,1-0.9V77.5h12.1l18.5,44.4c-2.1,4.7-4.2,5.6-7,5.6c-2.3,0-4.7-0.7-7.1-2
            c-0.2-0.1-0.5-0.1-0.8-0.1c-0.2,0.1-0.5,0.3-0.6,0.5l-4.4,9.6c-0.2,0.5,0,1,0.4,1.2c4.6,2.5,8.7,3.5,13.8,3.5
            c9.6,0,14.9-4.5,19.5-16.4l22.5-58c0.1-0.3,0.1-0.6-0.1-0.9c-0.2-0.3-0.5-0.4-0.8-0.4h-13.5c-0.4,0-0.8,0.3-0.9,0.6l-13.8,39.4
            l-15.1-39.5c-0.1-0.4-0.5-0.6-0.9-0.6L471.3,64.6L471.3,64.6L471.3,64.6z M442.5,64.5h-13c-0.5,0-1,0.4-1,0.9v56.5
            c0,0.5,0.4,0.9,1,0.9h13c0.5,0,1-0.4,1-0.9V65.5C443.4,64.9,443,64.5,442.5,64.5C442.5,64.5,442.5,64.5,442.5,64.5z M436.1,38.8
            c-5.1,0-9.3,4.2-9.3,9.3c0,5.1,4.2,9.3,9.3,9.3s9.3-4.2,9.3-9.3C445.4,42.9,441.2,38.8,436.1,38.8z M549.5,82.7
            c-5.1,0-9.1-4.1-9.1-9.1s4-9.2,9.2-9.2s9.1,4.1,9.1,9.1C558.7,78.5,554.6,82.7,549.5,82.7z M549.6,65.3c-4.7,0-8.2,3.7-8.2,8.3
            c0,4.5,3.5,8.2,8.2,8.2c4.7,0,8.2-3.7,8.2-8.3C557.7,69,554.2,65.3,549.6,65.3z M551.6,74.4l2.6,3.6H552l-2.3-3.3h-2V78h-1.8v-9.6
            h4.3c2.2,0,3.7,1.1,3.7,3.1C553.8,73.1,552.9,74.1,551.6,74.4L551.6,74.4z M550,70.1h-2.4v3h2.4c1.2,0,1.9-0.6,1.9-1.5
            C551.9,70.7,551.2,70.1,550,70.1z"
        />
      </svg>
    )
  }
}
