import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AlbumCard from "../components/album_card"
import "../stylesheets/App.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import Insta from "../components/insta"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import SJSingle from "../components/wtfudtf/wtfudtf"
import StayingIn from "../components/staying_in/staying_in"
import WtfButtons from "../components/wtfudtf/wtf_buttons"
import StayingInButtons from "../components/staying_in/staying_in_butttons"

function IndexPage() {
  return (
    <Layout>
      <SEO title="Steez Jobs • Staying In - W.T.F.U.D.T.F?" />
      <div className="wrap">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6">
              <div className="pt-4 album_art">
                <AlbumCard>
                  <StayingIn />
                </AlbumCard>
              </div>
              <StayingInButtons />
            </div>
            <div className="col-sm-6">
              <div className="pt-4 album_art">
                <AlbumCard>
                  <SJSingle />
                </AlbumCard>
              </div>
              <WtfButtons />
            </div>
            <div className="col-md-12">
              <div className="py-3">
                <Insta />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default IndexPage
