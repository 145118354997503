import React from "react"
import SpotifyButton from "../buttons/spotify_button"
import AppleMusicButton from "../buttons/apple_music_button"

const WtfButtons = () => (
  <div className="text-center">
    <AppleMusicButton
      album_title="W.T.F.U.D.T.F?"
      link="https://music.apple.com/us/artist/steez-jobs/1474225344"
    />
    <SpotifyButton
      album_title="W.T.F.U.D.T.F?"
      link="https://open.spotify.com/album/3iBcQo8MKJ1jGgkRkzlgmK"
    />
  </div>
)

export default WtfButtons
