import { OutboundLink } from "gatsby-plugin-google-analytics"
import React from "react"
import AppleMusicLogo from "../../images/standard-white.svg"
import Button from "./button"

const AppleMusicButton = props => (
  <Button>
    <OutboundLink
      className="music_links"
      href={props.link}
      title={props.album_title + " by Steez Jobs on Apple Music"}
    >
      <img src={AppleMusicLogo} className="music_icon" alt="Apple Music" />
    </OutboundLink>
  </Button>
)

export default AppleMusicButton
