import React from "react"
import { motion } from "framer-motion"

const styles = {
  borderRadius: 7,
  padding: "10px 20px",
  margin: "auto",
  outline: "none",
  border: "none",
  fontWeight: "600",
  cursor: "pointer",
}

export default function Button({ children }) {
  return (
    <motion.div
      className="icon_btn"
      style={styles}
      whileHover={{ scale: 1.04 }}
      whileTap={{ scale: 0.95, y: "5px" }}
    >
      {children}
    </motion.div>
  )
}
