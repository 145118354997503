import React from "react"
import SpotifyButton from "../buttons/spotify_button"
import AppleMusicButton from "../buttons/apple_music_button"

const StayingInButtons = () => (
  <div className="text-center">
    <AppleMusicButton
      album_title="Staying In"
      link="https://music.apple.com/us/album/staying-in-single/1512635777"
    />
    <SpotifyButton
      album_title="Staying In"
      link="https://open.spotify.com/album/16wAbW14HpxL1hXq1eBuKs"
    />
  </div>
)

export default StayingInButtons
