import { OutboundLink } from "gatsby-plugin-google-analytics"
import React from "react"
import SpotifyLogo from "../../images/icons/spotify_logo"
import Button from "./button"

const SpotifyButton = props => (
  <Button>
    <OutboundLink
      className="music_links"
      href={props.link}
      title={props.album_title + " by Steez Jobs on Spotify"}
    >
      <SpotifyLogo baseLayer="music_icon spotify" />
    </OutboundLink>
  </Button>
)

export default SpotifyButton
