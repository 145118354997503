import React, { Component } from "react"

export default class InstagramIcon extends Component {
  render() {
    return (
      <svg
        version="1.1"
        className={this.props.baseLayer}
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
      >
        <path
          d="M336,96c21.2,0,41.3,8.4,56.5,23.5S416,154.8,416,176v160c0,21.2-8.4,41.3-23.5,56.5S357.2,416,336,416H176
            c-21.2,0-41.3-8.4-56.5-23.5S96,357.2,96,336V176c0-21.2,8.4-41.3,23.5-56.5S154.8,96,176,96H336 M336,64H176
            c-61.6,0-112,50.4-112,112v160c0,61.6,50.4,112,112,112h160c61.6,0,112-50.4,112-112V176C448,114.4,397.6,64,336,64z"
        />
        <path
          d="M360,176c-13.3,0-24-10.7-24-24s10.7-24,24-24c13.2,0,24,10.7,24,24S373.2,176,360,176z M256,192c35.3,0,64,28.7,64,64
            s-28.7,64-64,64s-64-28.7-64-64S220.7,192,256,192 M256,160c-53,0-96,43-96,96s43,96,96,96s96-43,96-96S309,160,256,160z"
        />
      </svg>
    )
  }
}
