import React from "react"
import Image from "gatsby-image"
import useInstagram from "../hooks/use-instagram"
import InstagramIcon from "../images/icons/instagram_icon"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Insta = () => {
  const instaPhotos = useInstagram()
  const { username } = instaPhotos[0]

  return (
    <>
      <h3>
        <OutboundLink
          className="insta_link"
          href="https://www.instagram.com/fakesteezjobs/"
          target="_blank"
          rel="noreferrer noopener"
        >
          Follow <InstagramIcon baseLayer="insta_header_icon" />
          fakesteezjobs on Instagram{" "}
        </OutboundLink>
      </h3>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-between",
          margin: "1rem -0.5rem",
          padding: "0.5rem 0",
        }}
      >
        {instaPhotos.map(photo => (
          <a
            href={`https://instagram.com/p/${photo.id}/`}
            style={{
              boxShadow: 0,
              display: "block",
              margin: "0.5rem",
              maxWidth: "calc(33% - 1rem)",
              width: "120px",
              transition: "200ms box-shadow linear",
            }}
            key={photo.id}
          >
            <Image
              key={photo.id}
              style={{
                width: "100%",
                marginTop: 0,
              }}
              fluid={photo.fluid}
              alt={photo.caption}
            />
          </a>
        ))}
      </div>
      <div className="mb-5">
        <OutboundLink
          className="insta_link"
          href="https://www.instagram.com/fakesteezjobs/"
          target="_blank"
          rel="noreferrer noopener"
        >
          View More on <InstagramIcon baseLayer="insta_sub_icon" />
          Instagram
        </OutboundLink>
      </div>
    </>
  )
}

export default Insta
